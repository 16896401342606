import { useCallback, useState } from "react";
import { Response } from "models/api/binder/Personal";
import { useDispatch } from "react-redux";
import { useReduxState } from "hooks/useReduxState";
import {
  addPersonalBinderItems,
  setPersonalBinderItems,
  setPersonalTotalCount,
  changeIsAdding,
  setPersonalLimit,
  setPersonalListLimit,
  setPersonalOffset,
  setPersonalSortValue,
  changeIsLoading,
  setPersonalKeyword,
  setIsSearch
} from "modules/binders/list/actions";
import { setPersonalCardViewMode } from "modules/binders/saved/actions";
import { useToast } from "hooks/useToast";
import { actionCreators as headerActionCreators } from "modules/header";
import { request } from "utils/apiClient";
import { useLocation } from "react-router";
import { LAZY_LOAD_LIMIT } from "features/BinderPage/List";

type ErrorType = {
  message: string;
  body: {
    code: number;
    details: {
      apiErrorCode: string;
      message: string;
      stackTrace?: string;
      errorType?: string;
    }[];
  };
};

export const usePersonalBinder = () => {
  const dispatch = useDispatch();
  const binderItems = useReduxState(s => s.binders.list.personal);
  const totalCount = useReduxState(s => s.binders.list.personalTotalCount);
  const isLoading = useReduxState(s => s.binders.list.isLoading);
  const isAdding = useReduxState(s => s.binders.list.isAdding);
  const offset = useReduxState(s => s.binders.list.personalOffset);
  const limit = useReduxState(s => s.binders.list.personalLimit);
  const listLimit = useReduxState(s => s.binders.list.personalListLimit);
  const sortValue = useReduxState(s => s.binders.list.personalSortValue);
  const personalKeyword = useReduxState(s => s.binders.list.personalKeyword);
  const isSearch = useReduxState(s => s.binders.list.isSearch);
  const cardViewMode = useReduxState(s => s.binders.saved.personalCardViewMode);
  const [isError, setIsError] = useState(false);
  const toast = useToast();
  const location = useLocation();

  const fetchErrorHandle = useCallback(
    (error: ErrorType) => {
      const errors = [];
      if (error && error.body && error.body.details) {
        for (const detail of error.body.details) {
          errors.push(detail.message);
        }
      }
      toast.setErrors(
        error.message === "GATEWAY_TIMEOUT"
          ? "コレクションの読み込みがタイムアウトしました"
          : errors
      );
      setIsError(error.message === "GATEWAY_TIMEOUT");
    },
    [toast]
  );

  const fetchPersonalBinders = useCallback(
    async (searchLimit: number) => {
      const setLimit = cardViewMode
        ? LAZY_LOAD_LIMIT.card
        : searchLimit
        ? searchLimit
        : limit;
      dispatch(changeIsLoading(true));
      try {
        dispatch(setPersonalOffset(0));
        dispatch(setPersonalLimit(setLimit));
        dispatch(setIsSearch(!!personalKeyword));
        dispatch(
          setPersonalSortValue(
            !personalKeyword && sortValue === "3" ? "1" : sortValue
          )
        );
        const response = await request<Response>(
          "/binder/personal",
          {
            needFeedArticles: location.pathname === "/" || cardViewMode ? 1 : 0,
            offset: 0,
            limit: setLimit,
            sortKbn: !personalKeyword && sortValue === "3" ? "1" : sortValue,
            keyword: personalKeyword
          },
          "GET"
        );
        dispatch(setPersonalBinderItems(response.binderList));
        dispatch(setPersonalTotalCount(response.totalCount));
      } catch (error) {
        fetchErrorHandle(error as ErrorType);
      } finally {
        dispatch(changeIsLoading(false));
      }
    },
    [
      cardViewMode,
      dispatch,
      location.pathname,
      sortValue,
      personalKeyword,
      fetchErrorHandle,
      limit
    ]
  );

  const addPersonalBinders = useCallback(async () => {
    if (offset + limit < totalCount) {
      dispatch(changeIsAdding(true));
      dispatch(setPersonalOffset(offset + limit));
      const response = await request<Response>(
        "/binder/personal",
        {
          needFeedArticles: cardViewMode ? 1 : 0,
          limit,
          offset: offset + limit,
          sortKbn: sortValue,
          keyword: personalKeyword
        },
        "GET"
      );
      dispatch(addPersonalBinderItems(response.binderList));
      dispatch(setPersonalTotalCount(response.totalCount));
      dispatch(changeIsAdding(false));
    }
  }, [
    cardViewMode,
    dispatch,
    limit,
    personalKeyword,
    offset,
    sortValue,
    totalCount
  ]);

  const sortPersonalBinder = async (sortKbn: string) => {
    try {
      dispatch(setPersonalSortValue(sortKbn));
      dispatch(changeIsLoading(true));
      dispatch(setPersonalOffset(0));
      dispatch(setIsSearch(!!personalKeyword));
      dispatch(
        setPersonalSortValue(
          !personalKeyword && sortKbn === "3" ? "1" : sortKbn
        )
      );
      const response = await request<Response>(
        "/binder/personal",
        {
          needFeedArticles: cardViewMode ? 1 : 0,
          sortKbn,
          offset: 0,
          limit,
          keyword: personalKeyword
        },
        "GET"
      );
      dispatch(setPersonalBinderItems(response.binderList));
      dispatch(setPersonalTotalCount(response.totalCount));
    } catch (error) {
      fetchErrorHandle(error as ErrorType);
    } finally {
      dispatch(changeIsLoading(false));
    }
  };

  const deletePersonalBinder = async (binderId: string) => {
    try {
      await request("/binder/delete", { binderId }, "POST");
      dispatch(
        headerActionCreators.requestUpdate({ shouldLoadCollection: true })
      );
      return { success: true };
    } catch (error) {
      return {
        success: false,
        messages:
          (error as ErrorType).body?.details?.map(
            (item: any) => item.message
          ) ?? ""
      };
    }
  };

  const setCardViewMode = (isCardMode: boolean) => {
    dispatch(setPersonalCardViewMode(isCardMode));
  };

  const switchCardViewMode = useCallback(
    async (cardMode: boolean) => {
      try {
        const changedLimit = cardMode ? LAZY_LOAD_LIMIT.card : listLimit;
        if (!cardMode) {
          dispatch(setPersonalListLimit(changedLimit));
        }
        dispatch(setPersonalLimit(changedLimit));
        dispatch(setPersonalCardViewMode(cardMode));
        dispatch(setPersonalOffset(0));
        dispatch(setIsSearch(!!personalKeyword));
        dispatch(
          setPersonalSortValue(
            !personalKeyword && sortValue === "3" ? "1" : sortValue
          )
        );
        dispatch(changeIsLoading(true));
        const response = await request<Response>(
          "/binder/personal",
          {
            needFeedArticles: cardMode ? 1 : 0,
            sortKbn: !personalKeyword && sortValue === "3" ? "1" : sortValue,
            limit: changedLimit,
            keyword: personalKeyword
          },
          "GET"
        );
        dispatch(setPersonalBinderItems(response.binderList));
        dispatch(setPersonalTotalCount(response.totalCount));
      } catch (error) {
        fetchErrorHandle(error as ErrorType);
      } finally {
        dispatch(changeIsLoading(false));
      }
    },
    [dispatch, personalKeyword, sortValue, listLimit, fetchErrorHandle]
  );

  const changePage = useCallback(
    async (offset: number, limit: number) => {
      try {
        dispatch(setPersonalOffset(offset));
        dispatch(setPersonalLimit(limit));
        dispatch(setIsSearch(!!personalKeyword));
        dispatch(
          setPersonalSortValue(
            !personalKeyword && sortValue === "3" ? "1" : sortValue
          )
        );
        dispatch(changeIsLoading(true));
        const response = await request<Response>(
          "/binder/personal",
          {
            needFeedArticles: cardViewMode ? 1 : 0,
            offset,
            limit,
            sortKbn: !personalKeyword && sortValue === "3" ? "1" : sortValue,
            keyword: personalKeyword
          },
          "GET"
        );
        dispatch(setPersonalBinderItems(response.binderList));
        dispatch(setPersonalTotalCount(response.totalCount));
      } catch (error) {
        fetchErrorHandle(error as ErrorType);
      } finally {
        dispatch(changeIsLoading(false));
      }
    },
    [cardViewMode, dispatch, personalKeyword, sortValue, fetchErrorHandle]
  );

  const changeLimit = useCallback(
    async (limit: number) => {
      try {
        dispatch(setPersonalLimit(limit));
        dispatch(setPersonalListLimit(limit));
        dispatch(setPersonalOffset(0));
        dispatch(setIsSearch(!!personalKeyword));
        dispatch(
          setPersonalSortValue(
            !personalKeyword && sortValue === "3" ? "1" : sortValue
          )
        );
        dispatch(changeIsLoading(true));
        const response = await request<Response>("/binder/personal", {
          needFeedArticles: cardViewMode ? 1 : 0,
          offset: 0,
          limit,
          sortKbn: !personalKeyword && sortValue === "3" ? "1" : sortValue,
          keyword: personalKeyword
        });
        dispatch(setPersonalBinderItems(response.binderList));
        dispatch(setPersonalTotalCount(response.totalCount));
      } catch (error) {
        fetchErrorHandle(error as ErrorType);
      } finally {
        dispatch(changeIsLoading(false));
      }
    },
    [cardViewMode, dispatch, personalKeyword, sortValue, fetchErrorHandle]
  );

  const onKeywordSearch = useCallback(
    async (keyword: string) => {
      try {
        dispatch(setPersonalKeyword(keyword));
        dispatch(setIsSearch(!!keyword));
        dispatch(setPersonalOffset(0));
        dispatch(setPersonalSortValue(keyword ? "3" : "1"));
        dispatch(changeIsLoading(true));
        const response = await request<Response>("/binder/personal", {
          needFeedArticles: cardViewMode ? 1 : 0,
          offset: 0,
          limit,
          sortKbn: keyword ? "3" : "1",
          keyword
        });
        dispatch(setPersonalBinderItems(response.binderList));
        dispatch(setPersonalTotalCount(response.totalCount));
      } catch (error) {
        fetchErrorHandle(error as ErrorType);
      } finally {
        dispatch(changeIsLoading(false));
      }
    },
    [cardViewMode, dispatch, limit, fetchErrorHandle]
  );

  return {
    isLoading,
    sortValue,
    isError,
    binderItems,
    totalCount,
    fetchPersonalBinders,
    sortPersonalBinder,
    deletePersonalBinder,
    setCardViewMode,
    cardViewMode,
    addPersonalBinders,
    isAdding,
    limit,
    offset,
    switchCardViewMode,
    changePage,
    changeLimit,
    onKeywordSearch,
    personalKeyword,
    setPersonalKeyword,
    isSearch
  };
};
