import React from "react";
import { Link } from "react-router-dom";
import styles from "./style.scss";
import { useCard } from "./hook";
import { usePersonalBinder } from "../../PersonalBinderList/hook";
import { useToast } from "hooks/useToast";
import { BinderItem } from "models/BinderItem";
import cn from "classnames";
import { BinderActionSet, BinderType } from "models/BinderItem";
import { useIpAuthFlg } from "hooks/useIpAuthFlg";
import { ToolTip } from "features/ToolTip";
import { FitImage } from "features/FitImage";
import { BinderActions } from "../BinderActions";
import { openPopup } from "components/Common/Popup/Link";
import Button from "components/Common/Button/Text";
import { trackAction } from "utils/helper/analytics";
import PayTag from "components/Common/PayTag";

interface Props {
  type: BinderType;
  binderItem: BinderItem;
  setBinderActionModal?: (value: BinderActionSet) => void;
}

export const Card: React.FunctionComponent<Props> = props => {
  const ipAuthFlg = useIpAuthFlg();
  const binderInfo = props.binderItem.binderInfo;

  const { updatePinned, authority, updateMailCollection, limit } = useCard();

  const { fetchPersonalBinders } = usePersonalBinder();

  const toast = useToast();

  const onClickPin = async (prevPin: boolean) => {
    await updatePinned(binderInfo.binderId);
    await fetchPersonalBinders(limit);

    if (prevPin) {
      toast.setMessages(["ピン留めを解除しました"]);
      return;
    }
    toast.setMessages(["ピン留めをしました"]);
  };

  const binderMeta =
    props.type === "nikkei"
      ? binderInfo.updateDate
      : `${binderInfo.updateDate}：${binderInfo.createUserName}`;

  return (
    <div
      className={cn({
        [styles.component]: true,
        [styles.public]: props.type === "nikkei",
        // IPログイン時のスタイルを調整
        [styles.ipAuthFlg]: ipAuthFlg
      })}
      data-testid="BinderPage_card"
    >
      <div className={styles.binderTitle}>
        <div className={styles.titleWrapper}>
          <div
            className={styles.meta}
            data-testid="BinderPage-List-Components-Common-Card-meta"
          >
            {props.type === "personal" && authority?.pinned ? (
              binderInfo.pinnedFlg === "0" ? (
                <ToolTip text="ピン留めする">
                  <div
                    className={styles.pinDefault}
                    onClick={() => {
                      onClickPin(false);
                      trackAction("onClickMyCollectionPinned", {
                        location: "コレクション",
                        type: "一覧",
                        id: binderInfo.binderId,
                        name: binderInfo.caption,
                        action: "ピン留め"
                      });
                    }}
                    data-testid="BinderPage-List-Components-Common-Card-pinDefault"
                  ></div>
                </ToolTip>
              ) : (
                <ToolTip text="ピン留めを解除する">
                  <div
                    className={styles.pinActive}
                    onClick={() => {
                      onClickPin(true);
                      trackAction("onClickMyCollectionPinned", {
                        location: "コレクション",
                        type: "一覧",
                        id: binderInfo.binderId,
                        name: binderInfo.caption,
                        action: "ピン留め解除"
                      });
                    }}
                    data-testid="BinderPage-List-Components-Common-Card-pinActive"
                  ></div>
                </ToolTip>
              )
            ) : null}
            {props.type === "personal" && binderInfo.publicStatus === "1" && (
              <span className={styles.publicIcon}>公開済み</span>
            )}
            <div className={styles.metaText}>{binderMeta}</div>
          </div>
          <div className={styles.ellipsisWrapper}>
            <div className={styles.title} data-testid="BinderPage_card_title">
              <Link
                to={`/binder/${binderInfo.binderId}/article?type=${props.type}`}
              >
                {binderInfo.caption}
              </Link>
            </div>
          </div>
        </div>
        <div className={styles.metaWrapper}>
          {props.type !== "nikkei" && (
            <ToolTip
              text={
                binderInfo.mailAlertFlg === "1"
                  ? "コレクションメールを解除"
                  : "コレクションメールを設定"
              }
              className={styles.shadow}
            >
              <div
                className={`${
                  binderInfo.mailAlertFlg === "1" ? styles.mail : styles.unmail
                }`}
                onClick={async () => {
                  await updateMailCollection(binderInfo.binderId);
                  fetchPersonalBinders(limit);
                  binderInfo.mailAlertFlg === "1"
                    ? toast.setMessages(["コレクションメールを解除しました"])
                    : toast.setMessages(["コレクションメールを設定しました"]);
                }}
                data-testid="BinderPage-List-Components-Common-Card-mail"
              />
            </ToolTip>
          )}
          {props.type !== "nikkei" && (
            <ToolTip
              text={binderInfo.sharedFlg ? "共有中" : "共有する"}
              className={styles.shadow}
            >
              <div
                className={`${
                  binderInfo.sharedFlg ? styles.share : styles.unshare
                }`}
                onClick={() =>
                  props.setBinderActionModal &&
                  props.setBinderActionModal({
                    type: "share",
                    binderInfo: binderInfo
                  })
                }
                data-testid="BinderPage-List-Components-Common-Card-share"
              />
            </ToolTip>
          )}
          {props.type !== "nikkei" && (
            <BinderActions
              binderInfo={binderInfo}
              setBinderActionModal={props.setBinderActionModal}
            />
          )}
        </div>
      </div>
      <div className={styles.description}>{binderInfo.description}</div>
      <div className={styles.listWrapper}>
        <ul className={styles.vsList}>
          {props.binderItem.articleList.length > 0 &&
            props.binderItem.articleList.map((article, index) => (
              <li
                className={styles.item}
                key={index}
                onClick={() =>
                  openPopup({
                    query: {
                      pathname: "/binder",
                      articleId: article.articleId,
                      articleKbn: article.articleKbn,
                      mediaFeeType: article.mediaFeeType,
                      keyBodyFare: article.keyBodyFare
                    }
                  })
                }
                data-testid={`BinderPage-List-Components-Common-Card-articleList-li-${index}`}
              >
                <div className={styles.articleWrapper}>
                  <div className={styles.title}>{article.title}</div>
                  <div className={styles.meta}>{article.displayTime}</div>
                  <div
                    className={
                      article.thumbPath ? styles.thumbMeta : styles.meta
                    }
                  >
                    {article.sponserName && article.sponserName !== ""
                      ? article.sponserName
                      : article.mediaName}
                    {article.mediaFeeType === "1" && <PayTag isActive={true} />}
                  </div>
                </div>
                {article.thumbPath && (
                  <FitImage
                    src={article.thumbPath}
                    width="65px"
                    height="40px"
                    className={styles.articleThumbnail}
                  />
                )}
              </li>
            ))}
          {props.binderItem.articleList.length === 0 &&
            props.type === "personal" && (
              <Empty
                onClick={() =>
                  props.setBinderActionModal?.({
                    type: "edit",
                    binderInfo
                  })
                }
              />
            )}
        </ul>
      </div>
      <div className={styles.tabs}>
        <Link to={`/binder/${binderInfo.binderId}/corp?type=${props.type}`}>
          <div className={styles.tab}>
            <span>企業</span>
            <span>&nbsp;({props.binderItem.corpTotalCount})</span>
          </div>
        </Link>
        <Link to={`/binder/${binderInfo.binderId}/industry?type=${props.type}`}>
          <div className={styles.tab}>
            <span>業界</span>
            <span>&nbsp;({props.binderItem.industryTotalCount})</span>
          </div>
        </Link>
        <Link
          to={`/binder/${binderInfo.binderId}/statistics?type=${props.type}`}
        >
          <div className={styles.tab}>
            <span>統計</span>
            <span>&nbsp;({props.binderItem.statisticsTotalCount})</span>
          </div>
        </Link>
        <Link
          to={`/binder/${binderInfo.binderId}/saveArticle?type=${props.type}`}
        >
          <div className={styles.tab}>
            <span>保存記事</span>
            <span>&nbsp;({props.binderItem.saveArticleTotalCount})</span>
          </div>
        </Link>
        <Link
          to={`/binder/${binderInfo.binderId}/externalPage?type=${props.type}`}
        >
          <div className={styles.tab}>
            <span>ページ</span>
            <span>&nbsp;({props.binderItem.externalPageTotalCount})</span>
          </div>
        </Link>
      </div>
      {props.type === "nikkei" && !ipAuthFlg && (
        <div
          className={styles.cloneButton}
          onClick={() => {
            trackAction("onCopyToMyCollection", {
              id: props.binderItem.binderInfo.binderId,
              name: props.binderItem.binderInfo.caption,
              type: "一覧"
            });
            props.setBinderActionModal?.({
              type: "clone",
              binderInfo
            });
          }}
          data-testid="BinderPage-List-Components-Common-Card-CopyToMyCollection"
        >
          <span>マイコレクションにコピー</span>
        </div>
      )}
    </div>
  );
};

interface EmptyProps {
  onClick: () => void;
}

const Empty: React.FunctionComponent<EmptyProps> = props => (
  <div className={styles.emptyWrapper}>
    <div className={styles.emptyTitle}>
      フィード条件に合致する記事がありませんでした
    </div>
    <div className={styles.emptyBody}>
      あらかじめ条件を設定しておくことで関連する最新記事を自動で表示させることができます。
    </div>
    <Button
      className={styles.editButton}
      onClick={props.onClick}
      data-testid="BinderPage-List-Components-Common-Card-Empty-editButton"
    >
      フィード条件を設定する
    </Button>
  </div>
);
