import React, { useEffect, useMemo, useRef, useState } from "react";
import styles from "./style.scss";
import Modal from "components/Common/Modal";
import { SwitchTab } from "features/Tab";
import { useIpAuthFlg } from "hooks/useIpAuthFlg";
import { CorpName } from "./Tab/CorpName";
import { Industry } from "./Tab/Industry";
import { Collection } from "./Tab/Collection";
import { Recent } from "./Tab/Recent";
import Button from "components/Common/Button/Text";
import { SelectedItemTable } from "./SelectedItemTable";
import { UpperLimitModal } from "./UpperLimitModal";
import { CloseConfirmModal } from "features/BinderPage/Common/CloseConfirmModal";
import { Corp, MstL, MstM, MstS, SourceItem } from "models/CorpScreening";
import { trackAction } from "utils/helper/analytics";
import { findAnalyticsWithPath } from "utils/helper/url";

export interface Item {
  companyCode: string;
  companyName: string;
  stkCode: string;
  source: string;
  companyKbn?: string;
  baseOptFlg?: string;
}

interface SearchList {
  searchResult: Item[] | null;
  searchCorp: (
    companyName: string,
    suggestFlag: boolean,
    suggestMeta?: string | undefined
  ) => Promise<void>;
  type: string;
  isLoading: boolean;
  isLoadingSearch: boolean;
}

interface SelectMenu {
  corpList: Corp[];
  getNeedsMstL: () => Promise<void>;
  getNeedsMstM: ({ nkgncodel }: { nkgncodel: string }) => Promise<void>;
  getNeedsMstS: ({
    nkgncodel,
    nkgncodem
  }: {
    nkgncodel: string;
    nkgncodem: string;
  }) => Promise<void>;
  onClickNeedsMstS: (params: {
    allChecked: boolean;
    concatenatedCorpFilterValue: string;
    corpIndustyCode: string;
    mainFlgComp: string;
    nkgncodes: string;
  }) => void;
  clear: () => void;
  initSourceItems: () => void;
  needsMstL: MstL[];
  needsMstM: MstM[];
  needsMstS: MstS[];
  sourceItems: SourceItem[];
  loadingMstM: boolean;
  loadingMstS: boolean;
  loadingCorp: boolean;
  disableOverseas?: boolean;
}

interface Company {
  companyCode: string;
  companyKbn: string;
  companyName: string;
  diffflg: string;
  stkCode: string;
  source: string;
}

interface RecentList {
  getRecent: () => Promise<void>;
  selectCorp: () => void;
  recentList: Company[];
}

interface CollectionList {
  collections: {
    binderId: string;
    caption: string;
  }[];
  corporations: Item[];
  isLoading: boolean;
  getBinders: () => void;
  onSelectCollection: (id: string) => void;
}

interface Props {
  isOpen: boolean;
  defaultSelectedItems: Item[];
  searchList: SearchList;
  selectMenu: SelectMenu;
  recentList: RecentList;
  collection: CollectionList;
  sendAlertAtlas?: boolean;
  onClose: () => void;
  onChange: (corps: Item[]) => void;
  onSelectedItemButtonClick?: () => void;
  alretItemCodes?: string[];
  maxSelectableNumber?: number;
}

export const CompanyModal: React.FC<Props> = ({
  isOpen,
  defaultSelectedItems,
  searchList,
  selectMenu,
  recentList,
  collection,
  sendAlertAtlas = false,
  onClose,
  onChange,
  onSelectedItemButtonClick,
  alretItemCodes = [],
  maxSelectableNumber = 5000
}) => {
  const [selectedCorp, setSelectedCorp] = useState<Item[]>([]);
  const [visibleSelectedItemTable, setVisibleSelectedItemTable] = useState(
    false
  );
  const [isOpenUpperModal, setOpenUpperModal] = useState(false);
  const [visibleConfirmModal, setVisibleConfirmModal] = useState(false);

  const modalRef = useRef<React.RefObject<unknown> | null>(null);
  const ipAuthFlg = useIpAuthFlg();

  const tabs = useMemo(() => {
    const base = [
      {
        key: "corpName",
        text: "企業名から"
      },
      {
        key: "industry",
        text: "一覧から",
        onSwitch: () => selectMenu.getNeedsMstL()
      },
      {
        key: "recent",
        text: "最近見た企業から",
        onSwitch: () => recentList.getRecent()
      }
    ];

    if (ipAuthFlg) return base;

    const collectionTab = {
      key: "collection",
      text: "コレクションから"
    };

    return [...base, collectionTab];
  }, [ipAuthFlg, recentList, selectMenu]);

  const [tabKey, setTabKey] = useState<string>(tabs[0].key);
  const pageInfo = findAnalyticsWithPath(location.pathname);

  useEffect(() => {
    selectMenu.initSourceItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isOpen) return;

    setSelectedCorp(defaultSelectedItems);
    setTabKey(tabs[0].key);
    selectMenu.clear();
    collection.getBinders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const onSelect = (targets: Item[]) => {
    if (targets.length <= maxSelectableNumber) {
      setSelectedCorp(targets);
      return;
    }

    setSelectedCorp(targets.slice(0, maxSelectableNumber));
    setOpenUpperModal(true);
  };

  const onClear = () => {
    // 選択不可な企業以外は全てクリア
    setSelectedCorp(selectedCorp.filter(corp => corp.baseOptFlg === "9"));
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        title="企業選択"
        onClose={() => {
          if (!isOpen) return;

          setVisibleConfirmModal(true);
          return true;
        }}
        uncontrolled
        modalClassName={styles.modal}
        ref={modalRef}
      >
        <div className={styles.modalContent}>
          <div>
            <SwitchTab
              items={tabs}
              selectedKey={tabKey}
              onSwitch={item => setTabKey(item.key)}
              className={styles.tab}
            >
              <CorpName
                key={tabs[0].key}
                searchCorp={searchList.searchCorp}
                items={searchList.searchResult}
                selectedCorp={selectedCorp}
                type={searchList.type}
                maxSelectableNumber={maxSelectableNumber}
                isLoading={searchList.isLoading}
                onSelect={corp => onSelect(corp)}
              />
              <Industry
                key={tabs[1].key}
                mstL={selectMenu.needsMstL}
                mstM={selectMenu.needsMstM}
                mstS={selectMenu.needsMstS}
                loadingMstM={selectMenu.loadingMstM}
                loadingMstS={selectMenu.loadingMstS}
                loadingCorp={selectMenu.loadingCorp}
                corpList={selectMenu.corpList}
                selectedCorp={selectedCorp}
                onSelect={corp => onSelect(corp)}
                getMstM={selectMenu.getNeedsMstM}
                getMstS={selectMenu.getNeedsMstS}
                getCorps={selectMenu.onClickNeedsMstS}
                sourceItems={selectMenu.sourceItems}
                maxSelectableNumber={maxSelectableNumber}
                disableOverseas={selectMenu.disableOverseas}
                windowRef={modalRef}
                alretItemCodes={alretItemCodes}
              />
              <Recent
                key={tabs[2].key}
                items={recentList.recentList}
                selectedCorp={selectedCorp}
                maxSelectableNumber={maxSelectableNumber}
                onSelect={corp => onSelect(corp)}
              />
              {!ipAuthFlg && (
                <Collection
                  key={tabs[3].key}
                  collections={collection.collections}
                  corporations={collection.corporations}
                  selectedCorp={selectedCorp}
                  maxSelectableNumber={maxSelectableNumber}
                  onSelectCollection={collection.onSelectCollection}
                  isLoading={collection.isLoading}
                  onSelectCorp={onSelect}
                />
              )}
            </SwitchTab>
            <div className={styles.selectedButton}>
              <div
                className={styles.button}
                onClick={() => {
                  setVisibleSelectedItemTable(!visibleSelectedItemTable);
                  onSelectedItemButtonClick && onSelectedItemButtonClick();
                }}
              >
                <span className={styles.icon} />
                選択済み項目を表示
              </div>
            </div>
            {visibleSelectedItemTable && (
              <SelectedItemTable
                items={selectedCorp}
                onDelete={item => {
                  setSelectedCorp(
                    selectedCorp.filter(
                      corp => corp.companyCode !== item.companyCode
                    )
                  );
                }}
                onClear={onClear}
              />
            )}
          </div>
          <div className={styles.buttonContainer}>
            <Button
              onClick={() => {
                if (sendAlertAtlas) {
                  trackAction("clickAlertConfirmAddCorpButton", {
                    tab_id: tabs.find(t => t.key === tabKey)?.text,
                    pageName: pageInfo.pageName,
                    category1: pageInfo.categories[0],
                    category2: pageInfo.categories[1],
                    category3: pageInfo.categories[2]
                  });
                }
                onChange(selectedCorp);
              }}
              className={styles.button}
            >
              企業を設定
            </Button>
          </div>
        </div>
      </Modal>
      <UpperLimitModal
        isOpen={isOpenUpperModal}
        onClose={() => setOpenUpperModal(false)}
        maxSelectNumber={maxSelectableNumber}
      />
      <CloseConfirmModal
        isOpen={visibleConfirmModal}
        keepButtonText="入力を再開"
        onClickKeepButton={() => {
          setVisibleConfirmModal(false);
        }}
        onClickCloseButton={() => {
          setVisibleConfirmModal(false);
          onClose();
        }}
      />
    </>
  );
};
