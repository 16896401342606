import React from "react";
import styles from "./style.scss";
import { Route } from "react-router";
import Heading from "components/Common/Heading";
import Button from "components/Common/Button/Text";
import { Tab } from "features/Tab";
import { PersonalBinderList } from "./Components/PersonalBinderList";
import { NikkeiBinderList } from "./Components/NikkeiBinderList";
import { useIpAuthFlg } from "hooks/useIpAuthFlg";
import { useBinderAction } from "../Common/BinderActionModal/hook";
import { useLocation } from "react-router";
import { FileImport } from "./Components/FileImport";
import { usePersonalBinder } from "./Components/PersonalBinderList/hook";
import { useReduxState } from "hooks/useReduxState";
import { useDispatch } from "react-redux";
import { setSeletedCorpItems } from "modules/binders/item/actions";

const tabItems = [
  {
    text: "マイコレクション",
    pathname: "/binder/personal"
  },
  {
    text: "日経コレクション",
    pathname: "/binder/public"
  }
];

export const LAZY_LOAD_LIMIT = {
  card: 6,
  list: 20
};

export const BinderList: React.FunctionComponent = () => {
  const { setBinderActionModal } = useBinderAction();
  const { fetchPersonalBinders } = usePersonalBinder();
  const ipAuthFlg = useIpAuthFlg();
  const location = useLocation();
  const limit = useReduxState(s => s.binders.list.personalLimit);
  const dispatch = useDispatch();

  return (
    <div className={styles.component} data-testid="BinderPage-List">
      <div className={styles.title}>
        <Heading level="2">コレクション一覧</Heading>
        <div className={styles.buttonWrapper}>
          {location.pathname === tabItems[0].pathname && (
            <FileImport
              onFinishImport={() => {
                fetchPersonalBinders(limit);
              }}
            />
          )}
          {!ipAuthFlg && (
            <AddBinderButton
              openModal={() => {
                setBinderActionModal({ type: "new" });
                dispatch(setSeletedCorpItems([]));
              }}
            />
          )}
        </div>
      </div>
      <Tab
        items={tabItems.filter(
          item => !ipAuthFlg || item.pathname === "/binder/public"
        )}
        borderColor="primary"
      />
      <Route
        path={tabItems[0].pathname}
        render={() => <PersonalBinderList />}
      />
      <Route path={tabItems[1].pathname} render={() => <NikkeiBinderList />} />
    </div>
  );
};

interface AddBinderButtonProp {
  openModal: () => void;
}

const AddBinderButton: React.FunctionComponent<AddBinderButtonProp> = props => (
  <div className={styles.new}>
    <Button
      className={styles.btn}
      onClick={() => {
        props.openModal();
      }}
      data-testid="BinderPage-List-addBinderButton"
    >
      新規コレクション作成
    </Button>
  </div>
);
