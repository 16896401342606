var escape = require("../../../../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* stylelint-disable */\n\n.src-features-Modal-CompanyModal-style__modal--BNtnP {\n  height: auto;\n  min-height: 624px;\n}\n\n.src-features-Modal-CompanyModal-style__modalContent--1vXA6 {\n  min-height: calc(100% - 48px);\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-flow: column;\n      flex-flow: column;\n  -ms-flex-pack: justify;\n      justify-content: space-between;\n  margin: 16px 0 32px;\n}\n\nul.src-features-Modal-CompanyModal-style__tab--1SRXl {\n  margin-bottom: 8px !important;\n}\n\n.src-features-Modal-CompanyModal-style__selectedButton--1ynMi {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-pack: center;\n      justify-content: center;\n  cursor: pointer;\n  margin-top: 16px;\n}\n.src-features-Modal-CompanyModal-style__selectedButton--1ynMi .src-features-Modal-CompanyModal-style__button--2D6cD {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-align: center;\n      align-items: center;\n  color: #00578e;\n  font-weight: bold;\n  line-height: 20px;\n}\n.src-features-Modal-CompanyModal-style__selectedButton--1ynMi .src-features-Modal-CompanyModal-style__button--2D6cD .src-features-Modal-CompanyModal-style__icon--UO94X {\n  background: url(" + escape(require("images/icon/arrow_down_circle.svg")) + ");\n  background-size: contain;\n  height: 16px;\n  width: 16px;\n  display: inline-block;\n  margin-right: 4px;\n}\n\n.src-features-Modal-CompanyModal-style__buttonContainer--2gYyu {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-pack: center;\n      justify-content: center;\n  margin-top: 16px;\n}\n.src-features-Modal-CompanyModal-style__buttonContainer--2gYyu .src-features-Modal-CompanyModal-style__button--2D6cD {\n  width: 240px;\n}", ""]);

// exports
exports.locals = {
	"lHeaderHeight": "56px",
	"lCompanyHeight": "114px",
	"h2FontSize": "28px",
	"h3FontSize": "20px",
	"bodyLineHeight": "20px",
	"lGnShrinkWindowWidth": "1591px",
	"tableVerticalPadding": "8px",
	"tableHorizontalPadding": "16px",
	"lPersonnelBreakWidth": "1599px",
	"cNavigationAccent": "#f57f17",
	"cTableBodyOdd": "#fff",
	"cTableBodyEven": "#f2f6f8",
	"cTableHover": "#c5ddeb",
	"cTableHeadOdd": "#eaf1f5",
	"cTableHeadEven": "#dee8ef",
	"zPageLoading": "2",
	"modal": "src-features-Modal-CompanyModal-style__modal--BNtnP",
	"modalContent": "src-features-Modal-CompanyModal-style__modalContent--1vXA6",
	"tab": "src-features-Modal-CompanyModal-style__tab--1SRXl",
	"selectedButton": "src-features-Modal-CompanyModal-style__selectedButton--1ynMi",
	"button": "src-features-Modal-CompanyModal-style__button--2D6cD",
	"icon": "src-features-Modal-CompanyModal-style__icon--UO94X",
	"buttonContainer": "src-features-Modal-CompanyModal-style__buttonContainer--2gYyu"
};